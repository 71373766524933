const axios = require('axios')
import React from "react"
import defaultConfig from '../config/defaultConfig'
// import encrypted from '../helper'

class Http extends React.Component  {
    constructor(props) {
        super(props)
        this.http = this.http.bind(this)
    }
    
    http(method, endpoint, params, data = {}, headerData = '') {
        const headers = {}
        headers['Access-Control-Allow-Headers'] = '*'
        headers['Authorization'] = this.getToken()
        const getUserData = JSON.parse(localStorage.getItem('userData'))
        if (getUserData && getUserData.eid) {
            headers['usedwhich'] = getUserData.eid.split('-')[0]
        }
        if (headerData) {
            for (const key in headerData) {
                headers[key] = headerData[key]
            }
        }
        return axios({
            method,
            url: `${defaultConfig.GAVL_BACKEND_URL}/${endpoint}`,
            headers,
            params,
            data
          })
            .then(response => response.data)
    }
    getToken() {
        return localStorage.getItem('accessToken')
      }
}
export default Http