// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    allRegion: []
  },
  reducers: {
    setRegionID: (state, action) => {
      state.regionID = action.payload
    },
    setAllRegion: (state, action) => {
      state.allRegion = action.payload
    }
  }
})

export const {
  setRegionID,
  setAllRegion
} = layoutSlice.actions

export default layoutSlice.reducer
